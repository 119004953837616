import { createContext, ReactNode, useEffect, useState } from "react";
import { UserType } from "../types";
import { useApi } from "../hooks/useApi";
import { Spinner } from "@chakra-ui/react";

export const CurrentUserContext = createContext<CurrentUserContextType>({
    user: null,
    isAdmin: false,
    isCustomerAdmin: false,
    isConsultant: false,
    readAccess: false,
    customerType: "",
    actingAs: null,
    portalOffline: false,
    scheduledResume: null,
});

interface CurrentUserContextType {
    user: UserType | null;
    isAdmin: boolean;
    isCustomerAdmin: boolean,
    isConsultant: boolean,
    readAccess: boolean,
    customerType: string,
    actingAs: string | null;
    portalOffline: boolean;
    scheduledResume: Date | null;
}

interface CurrentUserProviderProps {
    children: ReactNode;
}

export function CurrentUserProvider({ children } : CurrentUserProviderProps) {
    const {getCurrentUser, getActingAsFocus, ready: apiReady} = useApi();
    const [actingAs, setActingAs] = useState<string | null>(null);
    const [user, setUser] = useState<UserType | null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [readAccess, setReadAccess] = useState(false);
    const [isCustomerAdmin, setIsCustomerAdmin] = useState(false);
    const [isConsultant, setIsConsultant] = useState(false);
    const [customerType, setCustomerType] = useState("");
    const [portalOffline, setPortalOffline] = useState(false);
    const [scheduledResume, setScheduledResume] = useState<Date | null>(null);
    const [ready, setReady] = useState(false); 
    
    async function fetchUser() {
        try {
            const userData = await getCurrentUser();
            setUser(userData);
            setIsAdmin(userData.admin === "1");
            setIsCustomerAdmin(userData.customeradmin === "1");
            setReadAccess(userData.readaccess === "1");
            setIsConsultant(userData.consultant === "1");
            setCustomerType(userData.customerType)
            setPortalOffline(userData.portalOffline === "1");
            setScheduledResume(userData.scheduledResume ? new Date(userData.scheduledResume) : null);
        } catch (err) {
            console.error(err);
        }
    }

    async function fetchActingAsFocus() {
        try {
          const actingAsFocus = await getActingAsFocus();

          if (actingAsFocus) 
            setActingAs(actingAsFocus);
        } catch (err) {
          console.log("Could not load acting as focus.");
        }
    }

    useEffect(() => {
        async function loadCurrentUser() {
            await Promise.all([fetchUser(), fetchActingAsFocus()]);
            setReady(true);
        }

        if (apiReady)
            loadCurrentUser();
    }, [apiReady]);

    if (!ready) {
        return (
            <Spinner size="xl" />
        );
    }

    return (
        <CurrentUserContext.Provider value={{ user, isAdmin, isCustomerAdmin, readAccess, customerType, actingAs, isConsultant, portalOffline, scheduledResume }}>
            {children}
        </CurrentUserContext.Provider>
    );
}