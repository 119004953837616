import { FaCircleExclamation, FaDownload, FaFile } from "react-icons/fa6";
import { Box, Center, HStack, Icon, Link, Skeleton, VStack, Text } from "@chakra-ui/react"
import { useApi } from "../../../hooks/useApi";
import { useContext, useEffect, useState } from "react";
import { FileMetadata, NotificationContextType } from "../../../types";
import moment from "moment";
import { NotificationContext } from "../../../components/NotificationPane";
import download from 'downloadjs';

function FilesModule() {
  const [error, setError] = useState<boolean>(false);
  const { ready, getRecentFiles, downloadFile } = useApi();
  const [files, setFiles] = useState<FileMetadata[] | null>(null);
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  useEffect(() => {
    async function loadFiles() {
      setError(false);
      setFiles(null);

      try {
        const filesResponse = await getRecentFiles();
        setFiles(filesResponse)
      } catch (err) {
        setError(true);
        pushNotification("Could not load recent files.", "error");
      }
    }

    if (ready) 
      loadFiles();

  }, [ready, getRecentFiles]);

  const startDownload = async(dir: string, file: string) => {
    const byteArray = downloadFile(dir, file)
    download(new Blob([await byteArray]), file);
  }

  return(
    <Box minW={350} maxHeight={200} className="custom-scroll" overflowY="scroll">
      <VStack p={4} fontSize="lg" alignItems="flex-start">
        {
          error &&
          <Box w="100%">
            <Center><Icon as={FaCircleExclamation }/> Error loading data.</Center>
          </Box>
        }
        {
          !error && files && files.length === 0 &&
          <Box w="100%">
            <Center>Not enough data.</Center>
          </Box>
        }
        {
          !error && files && files.length > 0 &&
            files.map((file) => (
              <Link
                key={`${file.folder_path}\\${file.file_name}`}
                href="#"
                marginLeft={4}
                fontSize={'medium'}
                onClick={() =>
                  startDownload(`${file.folder_url}`, file.file_name)
                }
              >
                <HStack>
                  <Icon as={FaFile} />
                  <Text>{file.file_name} [{moment(file.file_modified).format("YYYY-MM-DD")}]</Text>
                  <Icon as={FaDownload} />
                </HStack>
                 
              </Link>
            ))
        }
        {!error && !files &&
          <>
            <Skeleton height="36px" width="100%" />
            <Skeleton height="36px" width="100%" />
            <Skeleton height="36px" width="100%" />
            <Skeleton height="36px" width="100%" />
            <Skeleton height="36px" width="100%" />
          </>
        }
      </VStack>
    </Box>
  )
}

export default FilesModule