import { ChartDisplay, DynamicChartData, HHData, HHDataVector } from "../types";
import * as Enums from "./Enums";

export function transformVectorData(data: HHDataVector[]): HHData[] {
    const transformedData: HHData[] = [];
    
    data.forEach((item: HHDataVector) => {
        const readDate = new Date(item.read_date);
        for (let i = 0; i < 48; i++) {
            const minutesToAdd = i * 30;
            const periodDate = new Date(readDate);
            periodDate.setMinutes(readDate.getMinutes() + minutesToAdd);
            const hhKey = `HH${String(i + 1).padStart(2, '0')}`;

            const transformedDataPoint: HHData =  {
                period_end: periodDate,
                kwh: item[hhKey] as number,
                site_group: item.site_group,
                account_name: item.account_name,
                supply_type: item.supply_type,
                account_id: item.account_id,
                granularity: Enums.PeriodEnum.HOURLY,
                supply_number: item.supply_number,
                site_address: item.site_address,
                site_id: item.site_id,
                supply_id: item.supply_id,
                supply_subtype: item.supply_subtype,
            };

            transformedData.push(transformedDataPoint);
        }
    });

    return transformedData;
}

export function formatForRecharts(data: HHData[], chartDisplay: ChartDisplay): { chartData: DynamicChartData[], uniqueGroups: string[] } {
    // Map of "timestamp" -> Map<groupName, consumption>
    const groupedData = new Map<string, Map<string, number>>();

    // Keep track of all unique groups (for chart series)
    const groupsSet = new Set<string>();

    // Keep track of import groups only (for average calculation)
    const importGroupsSet = new Set<string>();

    // Accumulate total import consumption for overall average
    let totalOverallConsumption = 0;

    data.forEach((dataPoint) => {
        if(chartDisplay?.supplies.includes(dataPoint.supply_number)) {
            let group: string | undefined;
            switch (chartDisplay?.grouping) {
                case Enums.GroupEnum.ACCOUNT:
                    group = dataPoint.account_name;
                    break;
                case Enums.GroupEnum.SITE_GROUP:
                    group = dataPoint.site_group;
                    break;
                case Enums.GroupEnum.SUPPLY_TYPE:
                    group = dataPoint.supply_type;
                    break;
                case Enums.GroupEnum.SITE:
                    group = dataPoint.site_address;
                    break;
                case Enums.GroupEnum.TOTAL:
                    group = "Total";
                    break;
                default:
                    break;
            }

            // Label export meters, if relevant
            if (dataPoint.is_export) group = `Export - ${group}`;

            // Only process if we have a group and a valid kWh
            if (group && dataPoint.kwh) {
                let kwh = dataPoint.kwh;

                if (dataPoint.is_export) {
                    // Make exports negative (for display, if needed)
                    kwh = kwh * -1;
                } else {
                    // Only add to the overall total if it's *import*
                    totalOverallConsumption += kwh;
                    importGroupsSet.add(group);
                }

                const period = new Date(dataPoint.period_end).toISOString();

                if (!groupedData.has(period)) {
                    groupedData.set(period, new Map<string, number>());
                }

                const groupMap = groupedData.get(period)!;
                groupMap.set(group, (groupMap.get(group) || 0) + kwh);

                groupsSet.add(group);
            }
        }
    });

    // Calculate overall average as total import / # of timestamps / # of import groups
    // (If no import groups exist, handle division by zero accordingly)
    const timestampsCount = groupedData.size;
    const importGroupCount = importGroupsSet.size;
    
    const overallAverage = 
        importGroupCount && timestampsCount 
            ? totalOverallConsumption / timestampsCount / importGroupCount 
            : 0;

    // Build the chart data array
    const chartData: DynamicChartData[] = [];

    groupedData.forEach((groupMap, period) => {
        const dataPoint: DynamicChartData = { date: new Date(period) };

        // Initialize all groups with 0 consumption
        groupsSet.forEach((group) => {
            dataPoint[group] = 0;
        });

        // Populate with actual consumption data
        groupMap.forEach((consumption, group) => {
            dataPoint[group] = consumption;
        });

        // Calculate the average consumption for just the import groups in this time period
        let importSum = 0;
        let countImportGroups = 0;
        groupMap.forEach((val, grp) => {
            // Skip anything that starts with "Export - "
            if (!grp.startsWith("Export - ")) {
                importSum += val;
                countImportGroups++;
            }
        });

        // This per‐period average (trend) should exclude export
        dataPoint.trend = countImportGroups
            ? importSum / countImportGroups 
            : 0;

        // Everyone gets the same overall average (again, import only)
        dataPoint.overallAverage = overallAverage;

        chartData.push(dataPoint);
    });

    // Sort by timestamp ascending
    chartData.sort((a, b) => a.date.getTime() - b.date.getTime());

    // Return data and list of all unique groups
    return {
        chartData,
        uniqueGroups: Array.from(groupsSet),
    };
}
