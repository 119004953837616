import { ReactNode, useContext, useEffect, useState } from 'react';
import { Box, Flex, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { NotificationContextType } from '../types';
import { NotificationContext } from '../components/NotificationPane';
import eyebrightFlower from '../assets/eyebright-flower.png';
import { useCurrentUser } from '../hooks/useCurrentUser';

type AdminRouteProps = {
    children: ReactNode;
}

const InvalidUserMessage = ({message}: {message: string}) => {
    const { logout } = useAuth();
    const { clearActingAs } = useApi();

    const handleClearActingAs = async () => {
        try {
            await clearActingAs();
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Flex
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Box p={5} shadow="md" borderWidth="1px">
                <VStack>
                    <img src={eyebrightFlower} width="100"/>
                    <Text>{message}</Text>
                    <Link color={"blue"} onClick={logout}>Logout here</Link>
                    <Link color={"blue"} onClick={handleClearActingAs}>Click here to clear "Acting As" settings</Link>
                </VStack>
            </Box>
        </Flex>
    )
}

const ValidUserRoute = ({ children }: AdminRouteProps) => {
    const { user, readAccess, portalOffline, scheduledResume } = useCurrentUser()
    const { hasAccounts, ready } = useApi();
    const [ accounts, setAccounts] = useState<boolean | null>(null);
    const [done, setDone] = useState(false);
    const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

    useEffect(() => {
        async function loadAccounts() {
            try {
                const accounts = await hasAccounts();
                setAccounts(accounts);
            } catch (err) {
                pushNotification("Could not load Account.", "error");
            } finally {
                setDone(true);
            }
        }

        if (ready) {
            loadAccounts();
        }
    }, [ready]);

    if (!done) {
        return (
            <Spinner size="xl" />
        );
    }

    if (done && !user) {
        return (
            <InvalidUserMessage message="Invalid user. Please contact your account manager."/>
        );
    }

    if (done && user && portalOffline) {
        let message = "The portal is temporarily offline for scheduled upgrade.";
        if (scheduledResume) {
            message += " We expect to be back online at " + scheduledResume.toLocaleString("en-GB", { timeZone: "Europe/London" });
        }

        return (
            <InvalidUserMessage message={message} />
        );
    }

    if (done && user && !readAccess) {
        return (
            <InvalidUserMessage message="You have not been approved for portal access. Please contact your account manager."/>
        );
    }

    if (done && user && !accounts) {
        return (
            <InvalidUserMessage message="You are not connected to any accounts. Please contact your account manager."/>
        );
    }

    return <>{children}</>;
};

export default ValidUserRoute;
