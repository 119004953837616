
import { Grid, GridItem, IconButton, Select } from "@chakra-ui/react"
import { FaDownload } from "react-icons/fa6"
import { useApi } from "../../hooks/useApi";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../components/NotificationPane";
import { NotificationContextType } from "../../types";

type FlexInfoSelectorProps = {
  selectedFlexOption: string;
  setSelectedFlexOption: (option: string) => void;
  handleExport: () => void;
  selectedTabIndex: number;
};

function FlexInfoSelector({ selectedFlexOption, setSelectedFlexOption, handleExport, selectedTabIndex }: FlexInfoSelectorProps) {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {ready, getFlexOptions} = useApi();
  const [optionList, setOptionList] = useState<string[]>([]);

  useEffect(() => {
      if (ready) loadOptionList();
  }, [ready, getFlexOptions]);

  async function loadOptionList() {
    try {
      const responseJSON = await getFlexOptions();
      setOptionList(responseJSON);
    } catch {
      pushNotification("Could not flex options.", "error")
    }
  }

  return (
    <Grid templateColumns='repeat(5, 1fr)' h='auto'>
      <GridItem />
      <GridItem display="flex" justifyContent="center" colSpan={3}>
        <Select value={selectedFlexOption} onChange={e => setSelectedFlexOption(e.target.value)} maxW={550} disabled={selectedTabIndex > 1}>
          {optionList.map(option => 
            // 'ALL METERS' is default empty string value in state
            <option key={((option.toUpperCase()==="GROUP: ALL") ? "" : option)} value={((option.toUpperCase()==="GROUP: ALL") ? "" : option)}>{option}</option>
          )}
        </Select>
      </GridItem>
      <GridItem display="flex" justifyContent="flex-end">
        {selectedTabIndex == 0 &&
          <IconButton icon={<FaDownload />} onClick={handleExport} aria-label={'Download'}>Export</IconButton>
        }
      </GridItem>
    </Grid>
  )
}

export default FlexInfoSelector