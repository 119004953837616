import { ReactNode } from 'react';
import eyebrightFlower from '../assets/eyebright-flower.png';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';


type InternalUserRouteProps = {
    children: ReactNode;
}

const InternalUserRoute = ({ children }: InternalUserRouteProps) => {
    const { customerType } = useCurrentUser()

    if (customerType !== 'EYEBRIGHT') {
        return (
            <Flex
                height="100vh"
                justifyContent="center"
                alignItems="center"
            >
            <Box p={5} shadow="md" borderWidth="1px">
                <VStack>
                <img src={eyebrightFlower} width="100"/>
                <Text>Access denied</Text>
                </VStack>
            </Box>
            </Flex>
        );
    }

    return <>{children}</>;
};

export default InternalUserRoute;
