import { useContext, useEffect, useMemo, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { AgGridReact } from "ag-grid-react";
import { caseInsensitiveComparator, dateValueFormatter, penceValueFormatter, percentValueFormatter, timezoneIndependantDateComparator } from "../../util/AgGridFunctions";
import { ColDef } from "ag-grid-enterprise";
import { FlexTradeLogType, NotificationContextType } from "../../types";
import { NotificationContext } from "../../components/NotificationPane";

function FlexTradeLog() {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {ready, getFlexTradeLog} = useApi();
  const [tradeLog, setTradeLog] = useState<FlexTradeLogType[] | undefined>(undefined);

  useEffect(() => {
      if (ready) loadTradeLog();
  }, [ready, getFlexTradeLog]);

  async function loadTradeLog() {
    try {
      const responseJSON = await getFlexTradeLog();
      setTradeLog(responseJSON);
    } catch {
      pushNotification("Could not flex trade log.", "error")
    }
  }

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef<FlexTradeLogType>[]>([
    { field: "trade_date", headerName: "Trade Date", rowGroup: true, hide: true, sort: "desc", filter: "agDateColumnFilter", filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter},
    { field: "supply_number", headerName: "Supply Number", rowGroup: true, hide: true },
    { field: "account_name", headerName: "Account Name" },
    { field: "period_start", headerName: "Period Start", width: 150, filter: "agDateColumnFilter", filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
    { field: "period_end", headerName: "Period End", width: 150, filter: "agDateColumnFilter", filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
    { field: "tranche", headerName: "Tranche", width: 150 },
    { field: "trade_basis", headerName: "Trade Basis", width: 150 },
    { field: "trade_pc", headerName: "Trade %", width: 150, valueFormatter: percentValueFormatter },
    { field: "rate_1", headerName: "Rate 1", width: 150, valueFormatter: penceValueFormatter },
    { field: "rate_2", headerName: "Rate 2", width: 150, valueFormatter: penceValueFormatter },
    { field: "rate_3", headerName: "Rate 3", width: 150, valueFormatter: penceValueFormatter },
    { field: "rate_4", headerName: "Rate 4", width: 150, valueFormatter: penceValueFormatter },
  ]);

  const defaultColDef: ColDef = useMemo(() => ({
    filter: 'agMultiColumnFilter',
    enableRowGroup: true,
    enableValue: true,
    comparator: caseInsensitiveComparator,
  }), []);

  return (
    <div className="ag-theme-quartz" style={{ height: '95%', width: '100%' }} >
      <AgGridReact<FlexTradeLogType>
          rowData={tradeLog}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowGroupPanelShow={"always"}
          groupDisplayType={'groupRows'}
      />
    </div>
  )
}

export default FlexTradeLog