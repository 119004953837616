import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useBoolean } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { BaseUserType, NotificationContextType } from "../types";
import { useApi } from "../hooks/useApi";
import { NotificationContext } from "./NotificationPane";
import Select from "react-select";
import { useCurrentUser } from '../hooks/useCurrentUser';

type ActingAsModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

function ActingAsModal({ isOpen, onClose }: ActingAsModalProps) {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {actingAs} = useCurrentUser();
  const {ready, getActingAsUsers, setActingAs } = useApi();
  const [userList, setUserList] = useState<BaseUserType[] | null>(null);
  const [actAsEmail, setActAsEmail] = useState<string | undefined>(actingAs ? actingAs : undefined);
  const [isLoading, setIsLoading] = useBoolean();
  
  useEffect(() => {
    async function loadActingAsUsers() {
      try {
        const actingAsUsers = await getActingAsUsers();
        // !!! when only showing unique contacts, group related accounts and display next to name (and possibly in search function of dropdown?)
        const uniqueActingAsUsers = [...new Map(actingAsUsers.map(item => [item["emailaddress"], item])).values()]
        setUserList(uniqueActingAsUsers);
        return;
      } catch (err) {
        pushNotification("Could not load acting as users.");
        return
      }
    }
    if (ready && isOpen && !userList) loadActingAsUsers();
  }, [ready, isOpen]);

  async function handleActingAsConfirm () {
    setIsLoading.on();
    try {
      await setActingAs(actAsEmail)
      onClose();
      pushNotification("Acting as set."); // !!! dont think this shows as page refreshes - maybe better way to refresh api calls for new acting as?
      setIsLoading.off();
      window.location.reload();
    } catch {
      pushNotification("Could not set acting as user.");
      return setIsLoading.off();
    }
  }

  const selectOptions = useMemo(
    () => [
      { value: undefined, label: "Myself (default)" }, // Option for "Myself"
      ...(userList
        ? userList.map((user) => ({
            value: user.emailaddress,
            label: `${user.firstname} ${user.lastname} - ${user.account_name}`,
          }))
        : []),
    ],
    [userList]
  );


  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent >
        <ModalHeader>Acting As</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody>
          {userList ? (
            <Select
              options={selectOptions}
              value={selectOptions.find((option) => option.value === actAsEmail) || null}
              onChange={(option) => setActAsEmail(option ? option.value : undefined)}
              isDisabled={isLoading}
              isSearchable={true}
              placeholder="Myself (default)"
            />
          ) : (
            <Center>
              <Spinner />
            </Center>
          )}
        </ModalBody>
        {userList &&
        <ModalFooter>
          <Button onClick={onClose} isDisabled={isLoading} m={2}>Cancel</Button>
          <Button onClick={handleActingAsConfirm} isLoading={isLoading} colorScheme='teal' m={2}>Confirm</Button>
        </ModalFooter>
        }
      </ModalContent>
    </Modal>
  )
}

export default ActingAsModal;