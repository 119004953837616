import { Flex, HStack, Icon, IconButton, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import Select, { OnChangeValue } from 'react-select';
import DatePicker from "react-datepicker";
import '../../styles/datepicker-custom.css';
import "react-datepicker/dist/react-datepicker.css";
import { ChartTypeOption, ChartDisplay, GroupingOption, OverlayOption, PeriodOption, Filters } from '../../types';
import { chartTypeOptions, groupingOptions, overlayOptions, periodOptions } from '../../util/Options';
import * as Enums from "../../util/Enums";
import { FaDownload } from 'react-icons/fa6';
import { useState, useEffect } from 'react';
import { MdUpdate } from 'react-icons/md';

type SelectionPaneProps = {
  filters: Filters;
  chartDisplay: ChartDisplay;
  onPeriodChange: (period: Enums.PeriodEnum) => void;
  onOverlayChange: (overlays: Enums.OverlayEnum[]) => void;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  onChartTypeChange: (chartType: Enums.ChartTypeEnum) => void;
  onGroupingChange: (chartType: Enums.GroupEnum) => void;
  updateQuery: () => void;
  handleExport: () => void;
};

export default function SelectionPane({
  filters,
  chartDisplay,
  onPeriodChange,
  onOverlayChange,
  onStartDateChange,
  onEndDateChange,
  onChartTypeChange,
  onGroupingChange,
  updateQuery,
  handleExport
} : SelectionPaneProps) {
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
  const [maxRangeText, setMaxRangeText] = useState<string>("");

  // loaded filters tracks the current loaded filters so update button behaviour can be influenced whether options have changed
  const [loadedFilters, setLoadedFilters] = useState<Filters>(filters);

  useEffect(() => {
    if (!filters.startDate) {
      setMaxDate(undefined);
      return
    }

    if (filters.period === Enums.PeriodEnum.YEARLY) {
      setMaxDate(undefined);
    } else if (filters.period === Enums.PeriodEnum.MONTHLY) {
      setMaxDate(new Date(filters.startDate.getFullYear() + 10, filters.startDate.getMonth(), filters.startDate.getDate()));
    } else if (filters.period === Enums.PeriodEnum.DAILY) {
      setMaxDate(new Date(filters.startDate.getFullYear(), filters.startDate.getMonth() + 3, filters.startDate.getDate()));
    } else if (filters.period === Enums.PeriodEnum.WEEKLY) {
      setMaxDate(new Date(filters.startDate.getFullYear() + 1, filters.startDate.getMonth(), filters.startDate.getDate()));
    } else if (filters.period === Enums.PeriodEnum.HOURLY) {
      setMaxDate(new Date(filters.startDate.getFullYear(), filters.startDate.getMonth(), filters.startDate.getDate() + 7));
    }
    
  }, [filters.period, filters.startDate]);

  useEffect(() => {
    if (filters.period === Enums.PeriodEnum.YEARLY)
      setMaxRangeText("");
    else if (filters.period === Enums.PeriodEnum.MONTHLY)
      setMaxRangeText("Max 10 years");
    else if (filters.period === Enums.PeriodEnum.DAILY)
      setMaxRangeText("Max 3 months");
    else if (filters.period === Enums.PeriodEnum.WEEKLY)
      setMaxRangeText("Max 1 year");
    else if (filters.period === Enums.PeriodEnum.HOURLY)
      setMaxRangeText("Max 7 days");
  }, [filters.period]);

  function onPeriodChangeIntermediate(selectedOptions: OnChangeValue<PeriodOption, false>) {
    if (selectedOptions)
      onPeriodChange(selectedOptions.value);
  }

  function onOverlayChangeIntermediate(selectedOptions: OnChangeValue<OverlayOption, true>) {
    onOverlayChange(selectedOptions.map(option => option.value));
  }

  function onChartTypeChangeIntermediate(selectedOptions: OnChangeValue<ChartTypeOption, false>) {
    if (selectedOptions)
      onChartTypeChange(selectedOptions.value);
  }

  function onGoupingChangeIntermediate(selectedOptions: OnChangeValue<GroupingOption, false>) {
    if (selectedOptions)
      onGroupingChange(selectedOptions.value);
  }

  function onDateRangeChange(date: [Date | null, Date | null]) {
    const [startDate, endDate] = date;

    onStartDateChange(startDate ? new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) : null);
    onEndDateChange(endDate ? new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())) : null);
  }

  function handleUpdateQuery() {
    setLoadedFilters(filters);
    return updateQuery();
  }

  const getDateFormat = () => {
    if (filters.period === Enums.PeriodEnum.YEARLY)
      return "yyyy"
    else if (filters.period === Enums.PeriodEnum.MONTHLY)
      return "MM/yyyy";
    else if (filters.period === Enums.PeriodEnum.HOURLY 
      || filters.period === Enums.PeriodEnum.DAILY 
      || filters.period === Enums.PeriodEnum.WEEKLY)
      return "dd/MM/yyyy"
  }

  const isWeekday = (date : Date) => {
    const day = date.getDay();
    return (filters.period !== Enums.PeriodEnum.WEEKLY || day === 0);
  };

  // filter "Day/Night" overlay unless on the hourly granularity
  let availableOverlays = overlayOptions;
  if (filters.period !== Enums.PeriodEnum.HOURLY) availableOverlays = availableOverlays.filter((x)=> x.value!==Enums.OverlayEnum.DAY_NIGHT)

  return (
    <Flex>
      <HStack>
        <Select
            value={periodOptions.find(option => option.value === filters.period) as PeriodOption}
            onChange={onPeriodChangeIntermediate}
            options={periodOptions}
          />
        <DatePicker
          selectsRange
          placeholderText='Date Range'
          maxDate={maxDate}
          startDate={filters.startDate == null ? undefined : filters.startDate}
          endDate={filters.endDate == null ? undefined : filters.endDate}
          onChange={onDateRangeChange}
          dateFormat={getDateFormat()}
          showMonthYearPicker={filters.period === Enums.PeriodEnum.MONTHLY}
          showYearPicker={filters.period === Enums.PeriodEnum.YEARLY}
          filterDate={isWeekday}
          isClearable={false}
        >  
          <Text>{maxRangeText}</Text>
        </DatePicker>        
        <IconButton aria-label='Update' fontSize={'2xl'} icon={<MdUpdate />} 
          isDisabled={filters == loadedFilters || !filters.startDate || !filters.endDate} onClick={handleUpdateQuery} 
          backgroundColor={(filters==loadedFilters || !filters.startDate || !filters.endDate) ? '' : 'blue.70'} animation={'backwards'}>
          Update
        </IconButton>
      </HStack>
      <Spacer/>
      <HStack>
        <Tooltip hasArrow placement="bottom" label={<Stack>
          <Text>Choose how chart data is grouped.</Text>
          <Text>Tip: limit to 5 groups for a clear chart.</Text>
        </Stack>}><Icon /></Tooltip>
        <Select
          placeholder="Grouping"
          value={groupingOptions.find(option => option.value === chartDisplay.grouping) as GroupingOption}
          onChange={onGoupingChangeIntermediate}
          options={groupingOptions}
        />
        <Select
          placeholder="Overlays"
          value={availableOverlays.filter(option => chartDisplay.overlays!.includes(option.value))}
          isMulti
          onChange={onOverlayChangeIntermediate}
          options={availableOverlays}
        />
        <Select
          placeholder="Chart Type"
          value={chartTypeOptions.find(option => option.value === chartDisplay.chartType) as ChartTypeOption}
          onChange={onChartTypeChangeIntermediate}
          options={chartTypeOptions}
        />
        <IconButton icon={<FaDownload />} onClick={handleExport} aria-label={'Download'}>Export</IconButton>
      </HStack>
    </Flex>
  );
}
