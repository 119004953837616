import { useAccount, useMsal } from "@azure/msal-react";
import { getAccessToken } from "../auth/authFunctions";
import { useCallback, useEffect, useState } from "react";
import { Account, AgreementType, Filters, HHData, HHDataVector, SubmitTicketType, SupplyType, TicketType, UserType, NewUserType, AccountSummary, dbImage, FileMetadata, BaseUserType, costSummaryType, MarketDataType, SupplierType, FlexContractType, FlexPositionType, FlexTradeLogType } from "../types";
import { hhMonthlyDataType } from "../pages/Supply";
import { trendDataType } from "../pages/Home/Modules/TrendModule";

export function useApi() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (instance && account) setReady(true);
    }, [instance, account]);

    // Centralized headers and API request handling function
    const buildHeaders = useCallback(async (): Promise<Record<string, string>> => {
        if (!instance || !account) throw new Error("API not ready");

        const accessToken = await getAccessToken(instance, account);
        const headers: Record<string, string> = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        };

        return headers;
    },[instance, account]);

    const fetchApi = useCallback(
        async (url: string, method: string = "GET", body?: any): Promise<any> => {
        const headers = await buildHeaders();
        const options: RequestInit = {
            method,
            headers,
            ...(body ? { body: JSON.stringify(body) } : {}),
        };

        const response = await fetch(`${import.meta.env.VITE_API_URL}${url}`, options);

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        return response.json();
    },[buildHeaders]);

    const getAgreements = useCallback(
        async (supplyId: string): Promise<AgreementType[]> => {
        const data = await fetchApi(`/agreements?supply_id=${supplyId}`);
        return data as AgreementType[];
    },[fetchApi]);

    const getHHData = useCallback(async (filters: Filters): Promise<HHData[] | HHDataVector[]> => {
        const data = await fetchApi("/consumptions", "POST", filters);
        return data as HHData[] | HHDataVector[];
    },[fetchApi]);

    const resendInvite = useCallback(async (user: UserType): Promise<boolean> => {
        await fetchApi("/users/resendInvite", "POST", user);
        return true;
    },[fetchApi]);

    const getUsers = useCallback(async (): Promise<UserType[]> => {
        const data = await fetchApi("/users");
        return data as UserType[];
    }, [fetchApi]);

    const getActingAsUsers = useCallback(async (): Promise<BaseUserType[]> => {
        const data = await fetchApi("/actingAs/users");
        return data as BaseUserType[];
    },[fetchApi]);

    const setActingAs = useCallback(async (act_as_email: string | undefined): Promise<boolean> => {
        await fetchApi("/actingAs/setUser", "POST", { act_as_email });
        return true;
    },[fetchApi]);

    const getSupplies = useCallback(async (): Promise<SupplyType[]> => {
        const data = await fetchApi("/supplies");
        return data as SupplyType[];
    },[fetchApi]);

    const getSupply = useCallback(async (id: string): Promise<SupplyType> => {
        const data = await fetchApi(`/supply?id=${id}`);
        return data[0] as SupplyType;
    },[fetchApi]);

    const getSupplyHHData = useCallback(async (supply_number: string): Promise<hhMonthlyDataType[]> => {
        const data = await fetchApi("/hhdata", "POST", { supply_number });
        return data as hhMonthlyDataType[];
    },[fetchApi]);

    const getTrends = useCallback(async (utility: string): Promise<trendDataType[]> => {
        const data = await fetchApi("/trends", "POST", { utility });
        return data as trendDataType[];
    },[fetchApi]);

    const createTicket = useCallback(async (ticket: SubmitTicketType): Promise<string> => {
        const data = await fetchApi("/tickets/createCase", "POST", ticket);
        return data.portalId;
    },[fetchApi]);

    const getTickets = useCallback(async (): Promise<TicketType[]> => {
        const data = await fetchApi("/tickets");
        return data as TicketType[];
    },[fetchApi]);

    const hasAccounts = useCallback(async (): Promise<boolean> => {
        const data = await fetchApi("/hasAccounts");
        return data as boolean;
    },[fetchApi]);

    const getAccounts = useCallback(async (): Promise<Account[]> => {
        const data = await fetchApi("/accounts");
        return data as Account[];
    },[fetchApi]);

    const updateUser = useCallback(async (updatedUser: UserType): Promise<boolean> => {
        await fetchApi("/users/updateUser", "PUT", updatedUser);
        return true;
    },[fetchApi]);

    const deleteUser = useCallback(async (deleteUser: UserType): Promise<boolean> => {
        await fetchApi("/users/deleteUser", "DELETE", deleteUser);
        return true;
    },[fetchApi]);

    const addUser = useCallback(async (newUser: NewUserType): Promise<boolean> => {
        await fetchApi("/users/addUser", "POST", newUser);
        return true;
    },[fetchApi]);

    const getAccountSummary = useCallback(async (): Promise<AccountSummary[]> => {
        const data = await fetchApi("/accounts/accountSummary");
        return data as AccountSummary[];
    }, [fetchApi]);

    const getConsultantImage = useCallback(async (email: string): Promise<dbImage> => {
        const data = await fetchApi(`/accounts/consultantImage?emailaddress=${email}`);
        return data as dbImage;
    },[fetchApi]);

    const getRecentFiles = useCallback(async (): Promise<FileMetadata[]> => {
        const data = await fetchApi(`/files/recentfiles`);
        return data as FileMetadata[];
    },[fetchApi]);

    const downloadFile = useCallback(async (dir: string, file: string): Promise<Uint8Array> => {
        const data = await fetchApi("/files/download", "POST", { dir, file });
        return new Uint8Array(data.data);
    },[fetchApi]);

    const getCurrentUser = useCallback(async (): Promise<UserType> => {
        const data = await fetchApi("/users/currentUser");
        return data as UserType;
    },[fetchApi]);

    const getActingAsFocus = useCallback(async (): Promise<string> => {
        const data = await fetchApi("/actingAs/focus");
        return data[0]["focus_emailaddress"] as string;
    },[fetchApi]);

    const clearActingAs = useCallback(async (): Promise<boolean> => {
        await fetchApi("/actingAs/clear", "POST");
        return true;
    },[fetchApi]);

    const getAccountCostSummary = useCallback(async (account: Account): Promise<costSummaryType[]> => {
        const data = await fetchApi("/accounts/costSummary?account_id=" + account.account_id);
        return data as costSummaryType[];
    },[fetchApi]);

    const getElectricityMarketData = useCallback(async (): Promise<MarketDataType[]> => {
        const data = await fetchApi("/marketdata/electricity");
        return data as MarketDataType[];
    },[fetchApi]);

    const getGasMarketData = useCallback(async (): Promise<MarketDataType[]> => {
        const data = await fetchApi("/marketdata/gas");
        return data as MarketDataType[];
    },[fetchApi]);

    const getSuppliers = useCallback(async (): Promise<SupplierType[]> => {
        const data = await fetchApi("/suppliers");
        return data as SupplierType[];
    }, [fetchApi]);

    const getFlexPosition = useCallback(async (selected_option: string | null | undefined): Promise<FlexPositionType[]> => {
        let data;
        if (selected_option)
            data = await fetchApi(`/flex/?selected_option=${selected_option}`);
        else 
            data = await fetchApi("/flex");

        return data as FlexPositionType[];
    }, [fetchApi]);

    const getFlexOptions = useCallback(async (): Promise<string[]> => {
        const data = await fetchApi("/flex/options");
        return data as string[];
    }, [fetchApi]);

    const getFlexContract = useCallback(async (supply_number: string | null | undefined): Promise<FlexContractType[]> => {
        let data;
        if (supply_number)
            data = await fetchApi(`/flex/contract?supply_number=${supply_number}`);
        else 
            data = await fetchApi("/flex/contract");
        
        return data as FlexContractType[];
    }, [fetchApi]);

    const getFlexTradeLog = useCallback(async (): Promise<FlexTradeLogType[]> => {
        const data = await fetchApi("/flex/tradelog");
        return data as FlexTradeLogType[];
    }, [fetchApi]);

    return {
        ready,
        getSuppliers,
        getElectricityMarketData,
        getGasMarketData,
        getAccountCostSummary,
        clearActingAs,
        getActingAsFocus,
        getCurrentUser,
        downloadFile,
        getRecentFiles,
        getAgreements,
        getHHData,
        resendInvite,
        getUsers,
        getActingAsUsers,
        setActingAs,
        getSupplies,
        getSupply,
        getSupplyHHData,
        getTrends,
        createTicket,
        getTickets,
        hasAccounts,
        getAccounts,
        updateUser,
        deleteUser,
        addUser,
        getAccountSummary,
        getConsultantImage,
        getFlexPosition,
        getFlexOptions,
        getFlexContract,
        getFlexTradeLog,
    };
}