import { ValueFormatterParams } from "ag-grid-enterprise";
import moment from "moment";

export const caseInsensitiveComparator = (valueA : any, valueB: any) => {
    // Handle null or undefined values first
    if (valueA == null && valueB == null) return 0;
    if (valueA == null) return -1;
    if (valueB == null) return 1;
  
    // Check if both values are strings
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      const valA = valueA.toLowerCase();
      const valB = valueB.toLowerCase();
  
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    }
  
    // Fallback to default comparison for other types
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
};

export const timezoneIndependantDateComparator = (filterDate : Date, cellValue : any) => {
    if (!cellValue) return -1; // Treat empty/null as less than any filter date

    console.log(filterDate)
    console.log(cellValue)
  
    // Normalize to UTC for comparison
    const cellDate = new Date(cellValue);
    const normalizedCellDate = Date.UTC(
      cellDate.getFullYear(),
      cellDate.getMonth(),
      cellDate.getDate()
    );
  
    const normalizedFilterDate = Date.UTC(
      filterDate.getFullYear(),
      filterDate.getMonth(),
      filterDate.getDate()
    );
  
    if (normalizedCellDate < normalizedFilterDate) return -1;
    if (normalizedCellDate > normalizedFilterDate) return 1;
    return 0; // Dates are equal
};

export const dateValueFormatter = (params: ValueFormatterParams) => {
    if (!moment(params.value).isValid()) return params.value;
    return moment(params.value).format('DD/MM/YYYY')
}

export const yesNoValueFormatter = (params: ValueFormatterParams) => {
  if (params.value === true || params.value === "true") return "Yes";
  else return "No";
}

export const poundValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value) return params.value
  else return `£${params.value}`;
}

export const penceValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value) return params.value
  else return `${params.value}p`;
}

export const kwhValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value) return params.value
  else return `${params.value} kWh`;
}

export const percentValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value) return params.value
  else return `${params.value}%`;
}