import { Bar, CartesianGrid, Cell, ComposedChart, Label, LabelList, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { FlexPositionType } from "../../types";
import moment from "moment";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import "../../styles/custom-tooltip.css";
import { Text, Grid, GridItem, Table, Tbody, Td, Th, Thead, Tr, Flex } from "@chakra-ui/react";
import { FaChartLine } from "react-icons/fa6";

type FlexGraphPropTypes = {
  flexPosition: FlexPositionType[] | undefined;
  chartRef: React.MutableRefObject<HTMLDivElement | null>;
}

const stripePattern = (
  <defs>
    <pattern id="stripe" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
      <rect width="2" height="4" fill="#ffc658" />
    </pattern>
  </defs>
);

// adds grouped year x-axis and small tick lines between each year
function renderYearTick(tickProps: any){
  const { x, y, payload, visibleTicksCount } = tickProps;
  const { value, offset, index } = payload;
  const date = new Date(value);
  const month = date.getMonth();

  // checks to see if first or last year needs extra year tick as no middle month
  const missingMidMonth = (month > 6 && index===0) || (visibleTicksCount-1===index && month < 6)
  if (month === 6 || missingMidMonth) return <text x={x} y={y-2} textAnchor="middle" fill="grey">{date.getFullYear()}</text>; // !!! check visual with 1 month in a year, might get cut off
  // Marker for new year - unless contract starts in Jan
  if (month === 0 && index !== 0) return <path d={`M${Math.floor(x - offset) + 0.5},${y - 4}v${-35}`} stroke="grey" />;
  return <></>; // had to return an empty fragment as `null` is not an expected type for tick
};

function toCurrency(value: number | null) {
  if (!value) return "-";
  return `£${value.toLocaleString()}`;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (!active || !payload || !payload[0]) return null;
  const data = payload[0].payload;
  const total = data.Stg_Cap_Cost + data.est_flt + data.PureCom_cost;
  const pieData = [
    { name: "untraded", value: 100-data.trd_vol },
    { name: "traded", value: data.trd_vol },
  ]
  return (
    <div className="custom-tooltip">
      <Grid templateColumns='repeat(3, 1fr)' w={550}>
        <GridItem rowSpan={2} colSpan={2}>
          <Table size='sm'>
            <Thead>
              <Tr><Th colSpan={3} align='center'>{moment(new Date(label)).format('MMMM YYYY')}</Th></Tr>
            </Thead>
            <Tbody>
              <Tr><Td backgroundColor={'#ffc658'} /><Td>Pure Commodity Cost</Td><Td>{toCurrency(data.PureCom_cost)}</Td></Tr>
              <Tr><Td backgroundColor={'#82ca9d'} /><Td>Non Commodity Cost</Td><Td>{toCurrency(data.est_flt)}</Td></Tr>
              <Tr><Td backgroundColor={'#8884d8'} /><Td>Std Charge & Capacity</Td><Td>{toCurrency(data.Stg_Cap_Cost)}</Td></Tr>
              <Tr fontWeight={'bold'}><Td /><Td>ESTIMATED MONTH TOTAL</Td><Td>{toCurrency(total)}</Td></Tr>
            </Tbody>
          </Table>
        </GridItem>
        <GridItem>
          <Flex align={'center'}><FaChartLine style={{marginRight: '4px'}} /><Text>Est. {(data.mth_kwh) ? data.mth_kwh.toLocaleString() : 0} kWh</Text></Flex>
        </GridItem>
        <GridItem>
          <ResponsiveContainer>
            <PieChart  title="Traded">
              <Pie data={pieData} startAngle={90} endAngle={270} innerRadius={40} outerRadius={65} paddingAngle={5} dataKey="value" >
                <Label fontWeight={'bold'}  value={`${data.trd_vol}% Traded`} position={'center'} />
                <Cell key={`untraded`} fill='url(#stripe)' />
                <Cell key={`traded`} fill='#ffc658' />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </GridItem>
      </Grid>
    </div>
  );
};

function FlexGraph({ flexPosition, chartRef }: FlexGraphPropTypes) {
  return (
    <ResponsiveContainer ref={chartRef} width="100%" height="100%">
      <ComposedChart data={flexPosition} margin={{ left: 40, right: 40, bottom: 15 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="cmonth" tickFormatter={(tickProps) => moment(new Date(tickProps)).format('MMM')} />
        <XAxis dataKey="cmonth" tick={renderYearTick} axisLine={false} tickLine={false} interval={0} height={1} scale="band" xAxisId="quarter" />
        {/* Left axis */}
        <YAxis yAxisId="right" tick={{ width: 100 }} orientation="right" tickFormatter={(tickProps) => `${tickProps.toLocaleString()} kWh`} />
        {/* Right axis */}
        <YAxis yAxisId="left" tickFormatter={(tickProps) => `£${tickProps.toLocaleString()}`} />

        <Tooltip content={<CustomTooltip />} />
        <Legend wrapperStyle={{ paddingTop: "20px" }} />

        {/* Use right axis for Stg_Cap_Cost, NonCom_cost, PureCom_cost */}
        <Bar dataKey="Stg_Cap_Cost" name="Std Charge & Capcity" stackId="a" fill="#8884d8" yAxisId="left" />
        <Bar dataKey="est_flt" name="Non Commodity Cost" stackId="a" fill="#82ca9d" yAxisId="left" />

        <Bar dataKey="PureCom_cost_traded" name="Pure Commodity Cost" stackId="a" fill="#ffc658" yAxisId="left" />

        {stripePattern}
        <Bar dataKey="PureCom_cost_untraded" legendType="none" stackId="a" fill="url(#stripe)" yAxisId="left">
          <LabelList dataKey="trd_vol" position="bottom" fontSize={14} formatter={(labelProps: number) => (labelProps>0 && labelProps<100) ? `${labelProps}%` : null} />
        </Bar>

        {/* Use left axis for Mth_Nom_Cons */}
        <Line dataKey="mth_kwh" name="Monthly Nominated Consumption" stroke="#000000" yAxisId="right" />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default FlexGraph