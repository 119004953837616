import { Box } from "@chakra-ui/react";
import BaseTemplate from "../components/BaseTemplate";
import FlexGraph from "./Flex/FlexGraph";
import FlexGrid from "./Flex/FlexGrid";
import FlexContractGrid from "./Flex/FlexContractGrid";
import FlexInfoSelector from "./Flex/FlexInfoSelector";
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useApi } from "../hooks/useApi";
import { FlexPositionType, NotificationContextType } from "../types";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import InternalUserRoute from "../util/InternalCustomerRoute";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import FlexTradeLog from "./Flex/FlexTradeLog";
import moment from "moment";
import { NotificationContext } from "../components/NotificationPane";

function Flex() {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {ready, getFlexPosition} = useApi();
  const [flexPosition, setFlexPosition] = useState<FlexPositionType[] | undefined>(undefined);
  const [selectedFlexOption, setSelectedFlexOption] = useState<string>("");
  const chartRef = useRef(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  useEffect(() => {
      if (ready) loadFlexPosition();
  }, [ready, getFlexPosition, selectedFlexOption]);

  function formatData(position: FlexPositionType[]) {
    position.forEach((pos) => {
      if (pos.cmonth) pos.cmonth = moment(pos.cmonth, 'YYYY-MM').toDate(); // converts cmonth to usable Date
      if (pos.trd_vol){
        // splitting pure commodity cost by volume traded for graph display purposes
        pos.PureCom_cost_traded = pos.PureCom_cost * pos.trd_vol/100;
        pos.PureCom_cost_untraded = pos.PureCom_cost * (100-pos.trd_vol)/100;
      }
    });
  }

  async function loadFlexPosition() {
    try {
      const responseJson = await getFlexPosition(selectedFlexOption);
      formatData(responseJson);
      setFlexPosition(responseJson);
    } catch {
      pushNotification("Could not flex position.", "error")
    }
  }

  const handleExport = useCallback(() => {
    if (chartRef.current === null) return;
    htmlToImage.toPng(chartRef.current)
      .then((dataUrl) => {
        download(dataUrl, 'chart.png');
      })
      .catch((err) => {
        console.error('Error exporting chart:', err);
      });
  }, [chartRef]);

  // tab background colours
  const selectedColor = '#FFFFFF';
  const unselectedColor = '#e1e5e5';

  return (
    <InternalUserRoute>
      <BaseTemplate  fitToWindow={true}>
        <BaseTemplate.Header>
          <FlexInfoSelector 
            selectedFlexOption={selectedFlexOption}
            setSelectedFlexOption={setSelectedFlexOption}
            handleExport={handleExport}
            selectedTabIndex={selectedTabIndex}
          />
        </BaseTemplate.Header>
        <BaseTemplate.Body>
          <Tabs variant='enclosed' h={'100%'} isLazy onChange={(index) => setSelectedTabIndex(index)}>
            <TabList>
              {/* IF UPDATING TABS: make sure to update FlexInfoSelector hiding select/download components based on selectedTabIndex */}
              <Tab backgroundColor={unselectedColor} _selected={{ backgroundColor: selectedColor }}>Position Chart</Tab>
              <Tab backgroundColor={unselectedColor} _selected={{ backgroundColor: selectedColor }}>Position Data</Tab>
              <Tab backgroundColor={unselectedColor} _selected={{ backgroundColor: selectedColor }}>Trade Log</Tab>
              <Tab backgroundColor={unselectedColor} _selected={{ backgroundColor: selectedColor }}>Contracts</Tab>
            </TabList>
            <TabPanels  h={'100%'}>
              <TabPanel h={'100%'}>
                <Box h={750} maxH={'100%'}>
                  <FlexGraph flexPosition={flexPosition} chartRef={chartRef} />
                </Box>
              </TabPanel>
              <TabPanel h={'100%'}>
                <Box h={'100%'}>
                  <FlexGrid flexPosition={flexPosition} />
                </Box>
              </TabPanel>
              <TabPanel h={'100%'}>
                <Box h={'100%'}>
                  <FlexTradeLog />
                </Box>
              </TabPanel>
              <TabPanel h={'100%'}>
                <Box h={'100%'}>
                  <FlexContractGrid />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </BaseTemplate.Body>
      </BaseTemplate>
    </InternalUserRoute>
  )
}

export default Flex