import BaseTemplate from "../../components/BaseTemplate";
import { SelectedAccountProvider } from "../../contexts/SelectedAccountContext";
import AccountSelector from "../../components/AccountSelector";
import { CostSummaryGraph } from "./CostSummaryGraph";
import InternalUserRoute from "../../util/InternalCustomerRoute";

export function CostSummary() {
    return (
        <InternalUserRoute>
            <SelectedAccountProvider>
                <BaseTemplate fitToWindow={true}>
                    <BaseTemplate.Header>
                        <AccountSelector />
                    </BaseTemplate.Header>
                    <BaseTemplate.Body>
                        <CostSummaryGraph />
                    </BaseTemplate.Body>
                </BaseTemplate>
            </SelectedAccountProvider>
        </InternalUserRoute>
    );
}
