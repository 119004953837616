export const presetColors = [
    "#1b9e77", "#d95f02", "#7570b3", "#e7298a", "#66a61e", "#e6ab02", "#a6761d", "#666666"
];

export const presetSpecificColors: { [key: string]: string } = {
    "Electricity": "#1b9e77",
    "Gas": "#d95f02"
}

const blue = {
    10: "#eff9fb",   // lightest tint
    20: "#dff3f8",
    30: "#cfedf5",
    40: "#bfe7f1",
    50: "#afe2ee",
    60: "#9fdceb",
    70: "#8fd6e7",
    80: "#7fd0e4",
    90: "#6fcae1",
    100: "#5FC5DE",
    200: "#55b1c7",
    300: "#4c9db1",
    400: "#42899b",
    500: "#397685",
    600: "#2f626f",
    700: "#264e58",
    800: "#1c3b42",
    900: "#12272c",
}

const green = {
    10: "#f4faf2",
    20: "#eaf5e5",
    30: "#e0f0d9",
    40: "#d6ebcc",
    50: "#cce6c0",
    60: "#c1e1b3",
    70: "#b7dca6",
    80: "#add79a",
    90: "#a3d28d",
    100: "#99CD81",
    200: "#89b874",
    300: "#7aa467",
    400: "#6b8f5a",
    500: "#5b7b4d",
    600: "#4c6640",
    700: "#3d5233",
    800: "#2d3d26",
    900: "#1e2819",  // darkest shade
}

const grey = {
    10: "#f0f2f2",
    20: "#e1e5e5",
    30: "#d2d8d8",
    40: "#c3cbcc",
    50: "#b4bebf",
    60: "#a5b1b2",
    70: "#96a4a6",
    80: "#879799",
    90: "#788a8c",
    100: "#6A7D80",
    200: "#5f7073",
    300: "#546466",
    400: "#4a5759",
    500: "#3f4b4c",
    600: "#353e40",
    700: "#2a3233",
    800: "#1f2526",
    900: "#151819",
}

const purple = {
    10: "#f7f4f9",
    20: "#efe9f3",
    30: "#e8deed",
    40: "#e0d3e7",
    50: "#d9c8e1",
    60: "#d1bddb",
    70: "#c9b2d5",
    80: "#c2a7cf",
    90: "#ba9cc9",
    100: "#B391C3",
    110: "#a182af",
    120: "#8f749c",
    130: "#7d6588",
    140: "#6b5775",
    150: "#594861",
    160: "#473a4e",
    170: "#352b3a",
    180: "#231c26",
    190: "#110e13",
}

export const colourOverrides = {
    grey,
    purple,
    blue,
    green
}
