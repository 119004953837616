import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Icon, Link, Show, Tab, TabList, TabPanel, TabPanels, Tabs, useBoolean } from "@chakra-ui/react"
import TicketsForm from "../pages/Tickets/TicketsForm"
import TicketsTable from "../pages/Tickets/TicketsTable"
import BaseTemplate from "./BaseTemplate"
import { TbLayoutSidebarRightCollapse, TbLayoutSidebarRightExpand } from "react-icons/tb"
import InternalUserRoute from "../util/InternalCustomerRoute"

type TicketProps = {
  isOpen: boolean;
  onClose: () => void;
  supplyid?: string
}

function TicketsDrawer({isOpen, onClose, supplyid}: TicketProps) {

  const [drawerExpand, setDrawerExpand] = useBoolean(false);
  const drawerExpandIcon = (drawerExpand) ? TbLayoutSidebarRightCollapse : TbLayoutSidebarRightExpand;
  const drawerSize = (drawerExpand) ? "95%" : "50%";

  return (

    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent maxW={{ base: "90%", lg: drawerSize }}>
        <DrawerCloseButton />
        <DrawerHeader backgroundColor={"purple.100"} color={"white"}>
          <Link onClick={setDrawerExpand.toggle}><Show above='lg'><Icon as={drawerExpandIcon}/></Show></Link> Tickets
        </DrawerHeader>
        <DrawerBody backgroundColor={"purple.10"}>
          <InternalUserRoute>
            <BaseTemplate fitToWindow={true}>
              <BaseTemplate.Body>
                <Tabs colorScheme='purple' backgroundColor={"white"} isLazy align='start' variant='enclosed' h={"100%"}>
                  <TabList>
                    <Tab>New Ticket</Tab>
                    <Tab>Ticket History</Tab>
                  </TabList>
                  <TabPanels h={"100%"}>
                    <TabPanel>
                      <Box backgroundColor={"white"} border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"md"}>
                        <TicketsForm prefillSupplyId={supplyid} />
                      </Box>
                    </TabPanel>
                    <TabPanel h={"90%"}>
                      <TicketsTable/>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </BaseTemplate.Body>
            </BaseTemplate>
          </InternalUserRoute>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default TicketsDrawer