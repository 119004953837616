import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { ColDef } from "ag-grid-enterprise";
import { FlexContractType, NotificationContextType } from "../../types"
import { useContext, useEffect, useMemo, useState } from "react";
import { caseInsensitiveComparator, dateValueFormatter, penceValueFormatter, poundValueFormatter, timezoneIndependantDateComparator } from "../../util/AgGridFunctions";
import { useApi } from '../../hooks/useApi';
import { NotificationContext } from '../../components/NotificationPane';

function FlexContractGrid() {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
    const {ready, getFlexContract} = useApi();
    const [flexContract, setFlexContract] = useState<FlexContractType[] | undefined>(undefined);

    useEffect(() => {
        if (ready)
            loadFlexContract();
    }, [ready, getFlexContract]);

    async function loadFlexContract() {
      try {
        const responseJson = await getFlexContract(null);
        setFlexContract(responseJson);
      } catch {
        pushNotification("Could not flex contract.", "error")
      }
    }
    

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef<FlexContractType>[]>([
    { field: "account_name", headerName: "Account Name" },
    { field: "supplier_code", headerName: "Supplier Code" },
    { field: "model_ref", headerName: "Model Ref" },
    { field: "contract_ref", headerName: "Contract Ref" },
    { field: "contract_start", headerName: "Contract Start", filter: "agDateColumnFilter", filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
    { field: "contract_end", headerName: "Contract End", filter: "agDateColumnFilter", filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
    { field: "site_group", headerName: "Site Group" },
    { field: "site_label", headerName: "Site Label" },
    { field: "site_addr", headerName: "Site Address" },
    { field: "site_postcode", headerName: "Site Postcode" },
    { field: "supply_number", headerName: "Supply Number" },
    { field: "utility_type", headerName: "Utility Type" },
    { field: "supply_subtype", headerName: "Supply Subtype" },
    { field: "nom_eaq", headerName: "Nominated EAQ (kWh)", filter: "agNumberColumnFilter" },
    { field: "cap_kva", headerName: "Capacity (kVA)", filter: "agNumberColumnFilter" },
    { field: "cap_chg", headerName: "Capacity Charge", filter: "agNumberColumnFilter", valueFormatter: poundValueFormatter },
    { field: "rego", headerName: "REGO" },
    { field: "std_chg", headerName: "Standing Charge", filter: "agNumberColumnFilter", valueFormatter: penceValueFormatter },
    { field: "rate_1_name", headerName: "Rate 1 Name" },
    { field: "rate_1_flat", headerName: "Rate 1", filter: "agNumberColumnFilter", valueFormatter: penceValueFormatter },
    { field: "rate_1_tod", headerName: "Rate 1 Time of Day" },
    { field: "rate_2_name", headerName: "Rate 2 Name" },
    { field: "rate_2_flat", headerName: "Rate 2", filter: "agNumberColumnFilter", valueFormatter: penceValueFormatter },
    { field: "rate_2_tod", headerName: "Rate 2 Time of Day" },
  ]);
  
    
  const defaultColDef: ColDef = useMemo(() => ({
    filter: 'agMultiColumnFilter',
    enableRowGroup: true,
    enableValue: true,
    comparator: caseInsensitiveComparator,
  }), []);
  
  return (
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: '95%', width: '100%' }} // the Data Grid will fill the size of the parent container
    >
    <AgGridReact<FlexContractType>
        rowData={flexContract}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        suppressAggFuncInHeader={true}
        rowGroupPanelShow={"always"}
        groupDisplayType={'groupRows'}
        groupAllowUnbalanced
    />
    </div>
  )
}

export default FlexContractGrid